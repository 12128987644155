<template>
  <div>
    <maca-titulo-pagina
      icono="icon-home"
      nombrePagina="Inicio"
    ></maca-titulo-pagina>
  </div>
</template>

<style lang="css"></style>

<script>
export default {
  name: "home",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
